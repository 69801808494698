<template>
    <ConfirmationForm
        type="dedicated"
        :price="monthlyPrice"
        :setup-price="setupPrice"
        :configuration="mailSummary"
        :selection="selection"
        :redirectParams="`?redirect=dedicated-server/confirm&query=${queryParams}`"
    />
</template>

<script setup>
import { useAssortmentDedicated } from '../composables/assortmentDedicated';
import ConfirmationForm from './ConfirmationForm.vue';
import { getQueryParams } from '../../../utils/windowUtils';

const props = defineProps({
    assortmentContent: String,
    assortmentNetworkContent: String,
    assortmentIpContent: String,
});

const queryParams = getQueryParams('|');
const { monthlyPrice, setupPrice, mailSummary, selection } = useAssortmentDedicated(
    props.assortmentContent,
    props.assortmentNetworkContent,
    props.assortmentIpContent
);
</script>
