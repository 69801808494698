<template>
    <Configurator url="/colocation/confirm">
        <img
            src="/img/icons/23m/colocation.svg"
            alt="Colocation"
            class="mx-auto h-24 w-36"
        />
        <Columns>
            <Column>
                <ColumnTitle>{{ $t('configuratorColocation.base') }}</ColumnTitle>
                <template v-for="(racks, groupName) in assortment.racksGrouped">
                    <CategoryTitle>{{ groupName }}</CategoryTitle>
                    <div
                        v-for="rack in racks"
                        :key="rack.id"
                    >
                        <RadioBox
                            v-model="fields.rackId"
                            name="rack"
                            :badges="rack.badges"
                            :value="rack.id"
                            :title="rack.name"
                            :description="$t('configuratorColocation.mbsIncluded')"
                            :properties="rack.feed_hint"
                        />

                        <Range
                            v-if="rack.max_quantity"
                            v-model="fields.rackQuantity"
                            name="rackQuantity"
                            :disabled="String(fields.rackId) !== String(rack.id)"
                            :min="1"
                            :max="rack.max_quantity"
                        />
                    </div>
                </template>
            </Column>

            <Column>
                <ColumnTitle>{{ $t('configuratorColocation.power') }}</ColumnTitle>
                <CategoryTitle>Feeds</CategoryTitle>
                <Radios>
                    <template
                        v-for="feed in selection.rack.feeds"
                        :key="feed.id"
                    >
                        <Radio
                            v-model="fields.feedId"
                            :label="feed.name"
                            :value="feed.id"
                            name="feed"
                        />
                    </template>
                </Radios>
                <div>
                    <hint>{{ $t('configuratorColocation.powerSupply') }}</hint>
                    <hint>{{ $t('configuratorColocation.fuse') }}</hint>
                </div>
                <div>
                    <CategoryTitle>
                        {{ $t('configuratorColocation.electricalPower') }}
                    </CategoryTitle>
                    <div class="space-y-3">
                        <QuantityWithButtons
                            v-model="fields.power"
                            name="power"
                            :min="powerMin"
                            :max="selection.rack.power.watts_max"
                            @decrease="decreasePower"
                            @increase="increasePower"
                        />
                        <hint>{{ $t('configuratorColocation.eachKwh') }}</hint>
                    </div>
                </div>
            </Column>
            <Column>
                <NetworkConfigurationFieldset
                    :uplinks="selection.rack.uplinks"
                    :volumes="assortment.volumes"
                    :fields="fields"
                    :selection="selection"
                />
                <IpConfigurationFieldset
                    :assortment="assortment"
                    :fields="fields"
                    :selection="selection"
                />
            </Column>

            <Column>
                <ColumnTitle>{{ $t('configuratorGeneral.price') }}</ColumnTitle>
                <PriceBanner
                    :price="monthlyPrice"
                    :setup-price="setupPrice"
                />
                <Price
                    :price="monthlyPrice"
                    :setup_price="setupPrice"
                />
                <Availability>{{ $t('configuratorGeneral.available48hrs') }}</Availability>
                <div>
                    <hint>{{ $t('configuratorColocation.contractTerm') }}</hint>
                    <hint>{{ $t('configuratorColocation.noticePeriod') }}</hint>
                </div>
                <Confirm>{{ $t('configuratorGeneral.continue') }}</Confirm>
            </Column>
        </Columns>
    </Configurator>
</template>

<script setup>
import Availability from './Configurator/Availability.vue';
import Columns from './Configurator/Columns.vue';
import Column from './Configurator/Column.vue';
import Confirm from './Configurator/Confirm.vue';
import ColumnTitle from './Configurator/ColumnTitle.vue';
import Price from './Configurator/Price.vue';
import PriceBanner from './Configurator/PriceBanner.vue';
import QuantityWithButtons from './Configurator/QuantityWithButtons.vue';
import Radio from './Configurator/Radio.vue';
import Configurator from './Configurator/Configurator.vue';
import CategoryTitle from './Configurator/CategoryTitle.vue';
import Radios from './Configurator/Radios.vue';
import RadioBox from './Configurator/RadioBox.vue';
import Range from './Configurator/Range.vue';
import Hint from './Configurator/Hint.vue';
import { useAssortmentColocation } from '../composables/assortmentColocation';
import IpConfigurationFieldset from './IpConfigurationFieldset.vue';
import NetworkConfigurationFieldset from './NetworkConfigurationFieldset.vue';

const props = defineProps({
    assortmentContent: String,
    assortmentNetworkContent: String,
    assortmentIpContent: String,
});

const { fields, assortment, monthlyPrice, setupPrice, selection, powerMin } =
    useAssortmentColocation(
        props.assortmentContent,
        props.assortmentNetworkContent,
        props.assortmentIpContent
    );

const increasePower = () => {
    fields.power += selection.value.rack.power.watts_step;
};
const decreasePower = () => {
    fields.power -= selection.value.rack.power.watts_step;
};
</script>
